import React from 'react'

export default class OurStory extends React.Component {
  render() {
    return(
      <section>
        <div className="container" id="ourstory">
          <hr className="small" />
          <div className="grid-row">
            <div className="column-two-fifths">
              <h2>Our story</h2>
              <p>
                We are a family and friends with true passion for sailing.
                For years, we were dreaming of being closer to the sea,
                experiencing the elements and closeness to nature. Through
                fortune and hard work, we are now able to make it a reality.
                We have carefully picked and designed sailboats that are second
                home for us and provide us with incredible experiences
                every year.
              </p>
              <p>
                <strong className="lighter">We are now sharing this experience with you, like minded sailing lovers around the world!</strong>
              </p>
            </div>
            <div className="column-three-fifths">
              <div className="scattered-images">
                <div className="first">
                  <div></div>
                  <div>
                    <iframe id="ytplayer" title="Youtube video" type="text/html" width="100%" height="100%"
                      src="https://www.youtube.com/embed/A4G--EdVXKM?autoplay=1&mute=1&start=10&controls=0&playlist=A4G--EdVXKM&loop=1&showinfo=0"
                      frameBorder="0"></iframe>
                  </div>
                  <div>
                  </div>
                </div>
                <div className="second">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
