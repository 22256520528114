import React from 'react'

import { isMobileOnly } from 'react-device-detect'

import { A } from '../elements/all-elements'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'

export default class HeaderHome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false
    }
  }

  toggleVisible() {
    this.setState({visible: !this.state.visible})
  }

  render() {
    return(
      <header
        className={this.props.className + ((isMobileOnly) ? " mobile" : "") + ((this.state.visible) ? " visible" : "")} 
        style={{backgroundImage: `url(${this.props.headerImage})`}}
        >
        <div className="container">
          {(isMobileOnly) ? (
            <button
              className="button-transparent"
              onClick={this.toggleVisible.bind(this)}
              id="mobile-menu-bars"
              aria-label="toggle menu"><FontAwesomeIcon icon={faBars} /></button>
          ) : null}
          <nav>
            <ul>
              <li><A href="/" className="main">Nalu sailing</A></li>
              <li><A href="/nalu">Nalu</A></li>
              <li><A href="/keiki">Keiki</A></li>
              <li><A href="/contact">Contact</A></li> 
            </ul>
            <div className="button-group">
              <a href="https://www.instagram.com/sailingnalu/" target="_blank" rel="noreferrer" aria-label="instagram">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://www.linkedin.com/company/nalu-sailing" target="_blank" rel="noreferrer" aria-label="linkedin">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </div>
          </nav>
          {(this.props.children) ? (
            <div className="header-content">
              {this.props.children}
            </div>
          )
          : null }
            
        </div>
      </header>
    )
  }
}