import React from 'react'
import { withRouter } from 'react-router'

import PageSwitcher from './PageSwitcher'


class Button extends PageSwitcher {
  onClick(e) {
    e.preventDefault()
    const href = this.props.link
    const navigatedTo = this.changePage(href)
    if (navigatedTo === "page") {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
      <button
        onClick={this.onClick.bind(this)}
        className={this.props.className}
        >
        {this.props.children}
      </button>
    )
  }
}

export default withRouter(Button)