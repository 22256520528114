import React from 'react'
import Header from './sections/Header'
import Footer from './sections/Footer'
import Testimonials from './sections/Testimonials'

import { Button } from './elements/all-elements'
import InlineImageGallery from './components/InlineImageGallery'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from  '@fortawesome/free-solid-svg-icons'


import e1 from './img/nalu/e1.jpg'
import e2 from './img/nalu/e2.jpg'
import e4 from './img/nalu/e4.jpg'
import e6 from './img/nalu/e6.jpg'
import e7 from './img/nalu/e7.jpg'

import eLarge1 from './img/nalu/large/e1.jpg'
import eLarge2 from './img/nalu/large/e2.jpg'
import eLarge3 from './img/nalu/large/e3.jpg'
import eLarge4 from './img/nalu/large/e4.jpg'
import eLarge5 from './img/nalu/large/e5.jpg'
import eLarge6 from './img/nalu/large/e6.jpg'
import eLarge7 from './img/nalu/large/e7.jpg'

import i1 from './img/nalu/i1.jpg'
import i2 from './img/nalu/i2.jpg'
import i3 from './img/nalu/i3.jpg'
import i4 from './img/nalu/i4.jpg'
import i5 from './img/nalu/i5.jpg'
import i6 from './img/nalu/i6.jpg'
import i7 from './img/nalu/i7.jpg'
import i8 from './img/nalu/i8.jpg'
import i9 from './img/nalu/i9.jpg'
import i10 from './img/nalu/i10.jpg'
import i11 from './img/nalu/i11.jpg'


import iLarge1 from './img/nalu/large/i1.jpg'
import iLarge2 from './img/nalu/large/i2.jpg'
import iLarge3 from './img/nalu/large/i3.jpg'
import iLarge4 from './img/nalu/large/i4.jpg'
import iLarge5 from './img/nalu/large/i5.jpg'
import iLarge6 from './img/nalu/large/i6.jpg'
import iLarge7 from './img/nalu/large/i7.jpg'
import iLarge8 from './img/nalu/large/i8.jpg'
import iLarge9 from './img/nalu/large/i9.jpg'
import iLarge10 from './img/nalu/large/i10.jpg'
import iLarge11 from './img/nalu/large/i11.jpg'

import naluLayout from './img/nalu/layout.png'

import BookAdventure from './sections/BookAdventure'
// import Testimonials from './sections/Testimonials'

export default class NaluPage extends React.Component {
  constructor(props) {
    super(props)
    this.headerImages = [eLarge1, eLarge5, eLarge3, eLarge6, eLarge7]
    this.headerImagePosition = 0

    this.state = {
      headerImage: this.headerImages[0]
    }
    this.cacheImages(this.headerImages)
  }

  headerImageBackward() {
    this.headerImagePosition = (this.headerImagePosition === 0) ?
      (this.headerImages.length - 1) : (this.headerImagePosition - 1)
    
    this.setState({
      headerImage: this.headerImages[this.headerImagePosition]
    })
  }

  headerImageForward() {
    this.headerImagePosition = (this.headerImagePosition === (this.headerImages.length - 1)) ?
      0 : (this.headerImagePosition + 1)
    
    this.setState({
      headerImage: this.headerImages[this.headerImagePosition]
    })
  }

  cacheImages(images) {
    images.forEach((picture) => {
      const img = new Image()
      img.src = picture
    })
  }

  render() {
    return (
      <>
        <Header headerImage={this.state.headerImage}
          className="header-image-background header-image-gallery">
          <div className="grid-row grid-no-wrap">
            <div className="column-one-half">
              <button
                onClick={this.headerImageBackward.bind(this)}
                className="button-circle">
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
            </div>
            <div className="column-one-half column-align-right">
              <button 
                onClick={this.headerImageForward.bind(this)}
                className="button-circle">
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
          <div className="grid-row">
            <div className="column-one-half">
              <h1 className="nomargin">Nalu</h1>
              <p className="subtitle">Owner's version of Beneteau Oceanis 46.1 sailing yacht</p>
            </div>
            <div className="column-one-half column-align-right column-align-middle">
              <div className="button-group">
                {/* <button className="button-rounded button-white"><FontAwesomeIcon icon={faImages} /> See all photos</button> */}
                <Button link="/nalu#book" className="button-rounded">Request an offer</Button>
              </div>
            </div>
          </div>
          <div className="bottom-shadow" />
        </Header>
        <section>
          <div className="container">
            <div className="grid-row">
              <div className="column-full">
                <p>
                  Built and launched in 2020, Nalu is an owner's version of Beneteau Oceanis 46.1. A class leading sailboat in comfort, internal volume and sailing performance. Nalu is outfitted in premium packages and extras. She offers a spacious master suite forward with a dedicated head and a separate shower. Two large cabins and a second head with shower are situated aft, an additional bunk bed cabin is to the starboard. She is available for charter from a lovely ACI Marina Pomer on the southern tip of Istria, Croatia..
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="exterior">
          <div className="container">
            <div className="grid-row">
              <div className="column-one-half">
                <h2>Exterior Design &amp; Performance</h2>
                <p>
                  An incredibly efficient deck plan makes her very safe and easy to use. Plenty of extras make her a strong sailing performer and a very comfortable cruiser at the same time.
                </p>
              </div>
              <div className="column-one-half">
                <InlineImageGallery fullscreenEnabled>
                  <img src={e1} alt="exterior of nalu" largeimage={eLarge1} />
                  <img src={e2} alt="exterior of nalu" largeimage={eLarge2} />
                  <img src={e4} alt="exterior of nalu" largeimage={eLarge4} />
                  <img src={e6} alt="exterior of nalu" largeimage={eLarge6} />
                  <img src={e7} alt="exterior of nalu" largeimage={eLarge7} />
                </InlineImageGallery>
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-half">
                <p><strong>Powerful Sail Plan and Rigging</strong></p>
                <ul>
                  <li><span>Semi-full batten mainsail, lazy bag, 54m2</span></li>
                  <li><span>109% Furling Genoa, 52m2</span></li>
                  <li><span>Asymmetric Gennaker with snuffer (150m2, optional extra)</span></li>
                  <li><span>Full Bowsprit Rigging</span></li>
                  <li><span>4x Harken Winch / Portside Main Electric</span></li>
                  <li><span>Two steering wheels, two rudders</span></li>
                  <li><span>Sail Locker Forward</span></li>
                </ul>
              </div>
              <div className="column-one-half">
                <p><strong>Advanced Sailing Electronics</strong></p>
                <ul>
                  <li><span>Starboard Console: B&G Autopilot, B&G Multifunction Zeus 7” with Charts</span></li>
                  <li><span>Port Console: B&G Triton, B&G Multifunction Zeus 7” with Charts</span></li>
                  <li><span>Wind and Navigation Instruments, Compass</span></li>
                  <li><span>Fixed V60 VHF, AIS Receiver</span></li>
                  <li><span>Solar Panels and 500Ah of service batteries</span></li>
                </ul>
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-half">
                <p><strong>Comfort while sailing or at anchor</strong></p>
                <ul>
                  <li><span>Teak Cockpit</span></li>
                  <li><span>Large Seating for 8, Cockpit Cushions and 2 Sunbeds</span></li>
                  <li><span>Deck Shower</span></li>
                  <li><span>Bathing Platform 2,5m2</span></li>
                </ul>
              </div>
              <div className="column-one-half">
                <p><strong>&nbsp;</strong></p>
                <ul>
                  <li><span>Luxury Cockpit Table with Coolbox</span></li>
                  <li><span>Direct and Indirect Mood LED Lighting</span></li>
                  <li><span>Sprayhood, Bimini</span></li>
                  <li><span>Retractable Davits for Dinghy Storage</span></li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="interior">
          <div className="container">
            <div className="grid-row">
              <div className="column-one-half">
                <h2>Interior Comfort</h2>
                <p>White oak wood, master suit fore, plenty of natural light and selection of extras create a welcoming and home-like atmosphere</p>
              </div>
              <div className="column-one-half">
                <InlineImageGallery fullscreenEnabled>
                  <img src={i1} alt="interior of nalu" largeimage={iLarge1} />
                  <img src={i2} alt="interior of nalu" largeimage={iLarge2} />
                  <img src={i3} alt="interior of nalu" largeimage={iLarge3} />
                  <img src={i4} alt="interior of nalu" largeimage={iLarge4} />
                  <img src={i5} alt="interior of nalu" largeimage={iLarge5} />
                  <img src={i6} alt="interior of nalu" largeimage={iLarge6} />
                  <img src={i7} alt="interior of nalu" largeimage={iLarge7} />
                  <img src={i8} alt="interior of nalu" largeimage={iLarge8} />
                  <img src={i9} alt="interior of nalu" largeimage={iLarge9} />
                  <img src={i10} alt="interior of nalu" largeimage={iLarge10} />
                  <img src={i11} alt="interior of nalu" largeimage={iLarge11} />
                </InlineImageGallery>
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-half">
                <p><strong>Premium Interior Design</strong></p>
                <ul>
                  <li><span>Premium White Oak Interior</span></li>
                  <li><span>Spacious Saloon with U Shape Sofa / optional double berth</span></li>
                  <li><span>Oversized Windows and multiple points for ventilation</span></li>
                  <li><span>Extra Large Fully Equipped Galley</span></li>
                  <li><span>2 Burner Stove with Oven</span></li>
                  <li><span>Front Opening Fridge</span></li>
                  <li><span>Top Opening Fridge/Freezer</span></li>
                  <li><span>Seawater Foot Pump</span></li>
                </ul>
              </div>
              <div className="column-one-half">
                <p><strong>Smart Features</strong></p>
                <ul>
                  <li><span>LED Indirect Mood Lighting</span></li>
                  <li><span>Fans: Saloon + Cabins</span></li>
                  <li><span>Heating: Saloon + Cabins</span></li>
                  <li><span>Mosquito Nets and sun blinds</span></li>
                  <li><span>4G WiFi Internet</span></li>
                  <li><span>Smart TV</span></li>
                  <li><span>HiFi Radio with Bluetooth, Interior & Exterior Speakers</span></li>
                  <li><span>220V in saloon and galley and USB Sockets in each Cabin</span></li>
                </ul>
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-half">
                <p><strong>Full featured Safety Equipment</strong></p>
                <ul>
                  <li><span>Harnesses and Life Jackets x 10</span></li>
                  <li><span>Fire Extinguishers, Emergency signals</span></li>
                  <li><span>Electric and Manual Bilge Pump</span></li>
                </ul>
              </div>
              <div className="column-one-half">
                <p><strong>&nbsp;</strong></p>
                <ul>
                  <li><span>Life Raft</span></li>
                  <li><span>First Aid Kit</span></li>
                  <li><span>Bosun’s Chair</span></li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="characteristics">
          <div className="container">
            <div className="grid-row">
              <div className="column-one-half">
                <h2>Base Characteristics</h2>
                <p>
                  Nalu is a large cruising yacht, with a rare balance between elegance, usable space and performance. Her stepped hull opens up an incredible amount of space inside and yet her seakeeping performance is still impressive. Inside she offers maximum comfort to all sailors to enjoy even during colder evenings.
                </p>
                <div className="button-group">
                  <a href="/doc/brochure-nalu.pdf"
                    className="button-rounded"
                    target="_blank">
                    Download brochure
                  </a>
                </div>
              </div>
              <div className="column-one-half">
                <img src={naluLayout} className="image-full" alt="nalu layout" />
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-half">
                <div className="specs-row">
                  <p>Length</p>
                  <p>14.60 m</p>
                </div>
                <div className="specs-row">
                  <p>Width</p>
                  <p>4.50 m</p>
                </div>
                <div className="specs-row">
                  <p>Draft</p>
                  <p>2.35 m</p>
                </div>
                <div className="specs-row">
                  <p>Air Draft</p>
                  <p>20.31 m</p>
                </div>
                <div className="specs-row">
                  <p>Year Built</p>
                  <p>2020</p>
                </div>
                <div className="specs-row">
                  <p>Number of Cabins</p>
                  <p>4</p>
                </div>
                <div className="specs-row">
                  <p>Heads</p>
                  <p>2 + separate shower</p>
                </div>
                <div className="specs-row">
                  <p>Sleeping Capacity</p>
                  <p>10</p>
                </div>
                <div className="specs-row">
                  <p>Cabin Configuration</p>
                  <p>1 Owners + 2 Double<br /> + 1 Bunk Bed Cabin</p>
                </div>
              </div>
              <div className="column-one-half">
                <div className="specs-row">
                  <p>Water Tanks</p>
                  <p>570l Fresh Water in 2 tanks</p>
                </div>
                <div className="specs-row">
                  <p>Fuel Tank</p>
                  <p>200l Diesel</p>
                </div>
                <div className="specs-row">
                  <p>Black water holding</p>
                  <p>Tank for each head</p>
                </div>
                <div className="specs-row">
                  <p>Engine</p>
                  <p>57HP Yanmar</p>
                </div>
                <div className="specs-row">
                  <p>Bow Thruster</p>
                  <p>Yes</p>
                </div>
                <div className="specs-row">
                  <p>Electric Windlass</p>
                  <p>Yes</p>
                </div>
                <div className="specs-row">
                  <p>Webasto Heating</p>
                  <p>Yes</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Testimonials
          className="brown"
          />
        <BookAdventure
          id="book"
          filter="nalu"
          />
        <Footer />
      </>
    )
  }
}