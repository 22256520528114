import React from 'react'
import Header from './sections/Header'
import Footer from './sections/Footer'

import { Button } from './elements/all-elements'
import InlineImageGallery from './components/InlineImageGallery'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight} from  '@fortawesome/free-solid-svg-icons'

// small photos
import e1 from './img/keiki/e1.jpg'
import e2 from './img/keiki/e2.jpg'
import e3 from './img/keiki/e3.jpg'
import e4 from './img/keiki/e4.jpg'
import e5 from './img/keiki/e5.jpg'
import e6 from './img/keiki/e6.jpg'
import i1 from './img/keiki/i1.jpg'
import i2 from './img/keiki/i2.jpg'
import i3 from './img/keiki/i3.jpg'
import i4 from './img/keiki/i4.jpg'
import i5 from './img/keiki/i5.jpg'
import i6 from './img/keiki/i6.jpg'
import i7 from './img/keiki/i7.jpg'
import i8 from './img/keiki/i8.jpg'
import i9 from './img/keiki/i9.jpg'
import i10 from './img/keiki/i10.jpg'
import i11 from './img/keiki/i11.jpg'
import i12 from './img/keiki/i12.jpg'
import m12 from './img/keiki/m12.jpg'
import m14 from './img/keiki/m14.jpg'
import m21 from './img/keiki/m21.jpg'
import m22 from './img/keiki/m22.jpg'

// large photos
import eLarge1 from './img/keiki/large/e1.jpg'
import eLarge2 from './img/keiki/large/e2.jpg'
import eLarge3 from './img/keiki/large/e3.jpg'
import eLarge4 from './img/keiki/large/e4.jpg'
import eLarge5 from './img/keiki/large/e5.jpg'
import eLarge6 from './img/keiki/large/e6.jpg'
import iLarge1 from './img/keiki/large/i1.jpg'
import iLarge2 from './img/keiki/large/i2.jpg'
import iLarge3 from './img/keiki/large/i3.jpg'
import iLarge4 from './img/keiki/large/i4.jpg'
import iLarge5 from './img/keiki/large/i5.jpg'
import iLarge6 from './img/keiki/large/i6.jpg'
import iLarge7 from './img/keiki/large/i7.jpg'
import iLarge8 from './img/keiki/large/i8.jpg'
import iLarge9 from './img/keiki/large/i9.jpg'
import iLarge10 from './img/keiki/large/i10.jpg'
import iLarge11 from './img/keiki/large/i11.jpg'
import iLarge12 from './img/keiki/large/i12.jpg'
import mLarge12 from './img/keiki/large/m12.jpg'
import mLarge14 from './img/keiki/large/m14.jpg'
import mLarge21 from './img/keiki/large/m21.jpg'
import mLarge22 from './img/keiki/large/m22.jpg'


// header photos
import mLarge02 from './img/keiki/large/m02.jpg'
import mLarge03 from './img/keiki/large/m03.jpg'
import mLarge06 from './img/keiki/large/m06.jpg'
import mLarge08 from './img/keiki/large/m08.jpg'
import mLarge09 from './img/keiki/large/m09.jpg'
import mLarge11 from './img/keiki/large/m11.jpg'
import mLarge15 from './img/keiki/large/m15.jpg'
import mLarge17 from './img/keiki/large/m17.jpg'
import mLarge19 from './img/keiki/large/m19.jpg'
import mLarge20 from './img/keiki/large/m20.jpg'

import keikiLayout from './img/keiki/layout.png'

import BookAdventure from './sections/BookAdventure'
// import Testimonials from './sections/Testimonials'

export default class NaluPage extends React.Component {
  constructor(props) {
    super(props)
    this.headerImages = [mLarge20, mLarge19, mLarge15, mLarge09, mLarge08, mLarge02, mLarge06, mLarge03, mLarge11, mLarge17]
    this.headerImagePosition = 0

    this.state = {
      headerImage: this.headerImages[0]
    }
    this.cacheImages(this.headerImages)
  }

  headerImageBackward() {
    this.headerImagePosition = (this.headerImagePosition === 0) ?
      (this.headerImages.length - 1) : (this.headerImagePosition - 1)
    
    this.setState({
      headerImage: this.headerImages[this.headerImagePosition]
    })
  }

  headerImageForward() {
    this.headerImagePosition = (this.headerImagePosition === (this.headerImages.length - 1)) ?
      0 : (this.headerImagePosition + 1)
    
    this.setState({
      headerImage: this.headerImages[this.headerImagePosition]
    })
  }

  cacheImages(images) {
    images.forEach((picture) => {
      const img = new Image()
      img.src = picture
    })
  }

  render() {
    return (
      <>
        <Header headerImage={this.state.headerImage}
          className="header-image-background header-image-gallery">
          <div className="grid-row grid-no-wrap">
            <div className="column-one-half">
              <button
                onClick={this.headerImageBackward.bind(this)}
                className="button-circle">
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
            </div>
            <div className="column-one-half column-align-right">
              <button 
                onClick={this.headerImageForward.bind(this)}
                className="button-circle">
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
          <div className="grid-row">
            <div className="column-one-half">
              <h1 className="nomargin">Keiki</h1>
              <p className="subtitle">Owner's version of Beneteau Oceanis 40.1 sailing yacht</p>
            </div>
            <div className="column-one-half column-align-right column-align-middle">
              <div className="button-group">
                <Button link="/keiki#book" className="button-rounded">Request an offer</Button>
              </div>
            </div>
          </div>
          <div className="bottom-shadow" />
        </Header>
        <section>
          <div className="container">
            <div className="grid-row">
              <div className="column-full">
                <p>
                  Built  and launched in 2021, Keiki is an owner's version of Beneteau Oceanis 40.1. An entirely new boat design in the 12m sailboat range, Keiki offers unparalleled levels of comfort and sailing ability. Keiki is outfitted in premium packages and extras. Available for charter from a lovely ACI Marina Pomer on the southern tip of Istria, Croatia.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="exterior">
          <div className="container">
            <div className="grid-row">
              <div className="column-one-half">
                <h2>Exterior Comfort &amp; Performance</h2>
                <p>
                  Whether at anchor or in full sail, Keiki breaks the ground of what a 40 foot yacht can do. Her sail plan was picked to suit both calm and fresh wind conditions. The hull shape is optimized for true speed, and above waterline provides expansive deck and cockpit space.
                </p>
              </div>
              <div className="column-one-half">
                <InlineImageGallery fullscreenEnabled>
                  <img src={e1} alt="exterior of keiki" largeimage={eLarge1} />
                  <img src={e2} alt="exterior of keiki" largeimage={eLarge2} />
                  <img src={e3} alt="exterior of keiki" largeimage={eLarge3} />
                  <img src={e4} alt="exterior of keiki" largeimage={eLarge4} />
                  <img src={e5} alt="exterior of keiki" largeimage={eLarge5} />
                  <img src={e6} alt="exterior of keiki" largeimage={eLarge6} />
                  <img src={m12} alt="exterior of keiki" largeimage={mLarge12} />
                  <img src={m14} alt="exterior of keiki" largeimage={mLarge14} />
                  <img src={m21} alt="exterior of keiki" largeimage={mLarge21} />
                  <img src={m22} alt="exterior of keiki" largeimage={mLarge22} />
                </InlineImageGallery>
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-half">
                <p><strong>Powerful Sail Plan and Rigging</strong></p>
                <ul>
                  <li><span>Semi-full batten mainsail, lazy bag, 42m2</span></li>
                  <li><span>105% Furling Genoa, 35m2</span></li>
                  <li><span>Full Bowsprit Rigging</span></li>
                  <li><span>4x Harken Winch</span></li>
                  <li><span>Two steering wheels, two rudders</span></li>
                </ul>
              </div>
              <div className="column-one-half">
                <p><strong>Advanced Sailing Electronics</strong></p>
                <ul>
                  <li><span>Starboard console: B&G Autopilot; B&G Multifunction Zeus 7'' with Charts, Chain Control</span></li>
                  <li><span>Port console: B&G Triton 2 </span></li>
                  <li><span>Wind and Navigation Instruments, Compass</span></li>
                  <li><span>Fixed V60 VHF, AIS Receiver</span></li>
                  <li><span>Solar Panels and 400Ah of service batteries</span></li>
                </ul>
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-half">
                <p><strong>Comfort amenities</strong></p>
                <ul>
                  <li><span>Teak Cockpit</span></li>
                  <li><span>Large Seating for 6, Cockpit Cushions</span></li>
                  <li><span>Deck Shower</span></li>
                  <li><span>Bathing Platform 2m2</span></li>
                </ul>
              </div>
              <div className="column-one-half">
                <p><strong>&nbsp;</strong></p>
                <ul>
                  <li><span>Luxury Cockpit Table with Coolbox</span></li>
                  <li><span>Direct and Indirect Mood LED Lighting</span></li>
                  <li><span>Sprayhood, Bimini</span></li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="interior">
          <div className="container">
            <div className="grid-row">
              <div className="column-one-half">
                <h2>Interior Comfort</h2>
                <p>
                  Interior space as if on a 45 foot sailboat. Premium white oak interior creates a bright and a homelike atmosphere. Intelligent design, lots of storage options and additional extras provide you with everything you need. 
                </p>
              </div>
              <div className="column-one-half">
                <InlineImageGallery fullscreenEnabled>
                  <img src={i1} alt="interior of keiki" largeimage={iLarge1} />
                  <img src={i2} alt="interior of keiki" largeimage={iLarge2} />
                  <img src={i3} alt="interior of keiki" largeimage={iLarge3} />
                  <img src={i4} alt="interior of keiki" largeimage={iLarge4} />
                  <img src={i5} alt="interior of keiki" largeimage={iLarge5} />
                  <img src={i6} alt="interior of keiki" largeimage={iLarge6} />
                  <img src={i7} alt="interior of keiki" largeimage={iLarge7} />
                  <img src={i8} alt="interior of keiki" largeimage={iLarge8} />
                  <img src={i9} alt="interior of keiki" largeimage={iLarge9} />
                  <img src={i10} alt="interior of keiki" largeimage={iLarge10} />
                  <img src={i11} alt="interior of keiki" largeimage={iLarge11} />
                  <img src={i12} alt="interior of keiki" largeimage={iLarge12} />
                </InlineImageGallery>
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-half">
                <p><strong>Premium Interior Features</strong></p>
                <ul>
                  <li><span>White Oak Interior</span></li>
                  <li><span>Spacious Saloon with U Shape Sofa convertible to additional double bed</span></li>
                  <li><span>Oversized Windows with multiple points of ventilation</span></li>
                  <li><span>Large Fully Equipped Galley</span></li>
                  <li><span>2 Burner Stove with Oven</span></li>
                  <li><span>Top and Front Opening Fridge</span></li>
                  <li><span>Seawater foot pump</span></li>
                </ul>
              </div>
              <div className="column-one-half">
                <p><strong>Smart Features</strong></p>
                <ul>
                  <li><span>LED Indirect Mood Lighting</span></li>
                  <li><span>Fans: Saloon + Cabins</span></li>
                  <li><span>Heating: Saloon + Cabins</span></li>
                  <li><span>Mosquito Nets and window blinds</span></li>
                  <li><span>4G WiFi Internet</span></li>
                  <li><span>Smart TV</span></li>
                  <li><span>HiFi Radio with Bluetooth + Interior &amp; Exterior Speakers</span></li>
                  <li><span>220V in Saloon and Galley and USB Sockets in each Cabin</span></li>
                </ul>
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-half">
                <p><strong>Full Safety Equipment</strong></p>
                <ul>
                  <li><span>Harnesses and Life Jackets x 8</span></li>
                  <li><span>Fire Extinguishers and Emergency signals</span></li>
                  <li><span>Electric and Manual Bilge Pump</span></li>
                </ul>
              </div>
              <div className="column-one-half">
                <p><strong>&nbsp;</strong></p>
                <ul>
                  <li><span>Life Raft</span></li>
                  <li><span>First Aid Kit</span></li>
                  <li><span>Bosun’s Chair</span></li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="characteristics">
          <div className="container">
            <div className="grid-row">
              <div className="column-one-half">
                <h2>Base Characteristics</h2>
                <p>
                  Keiki is a 40-foot cruiser, with a new hull design by Marc Lombard. She offers unrivalled deck volume and interior space, and there are no concessions to performance.
                </p>
              </div>
              <div className="column-one-half">
                <img src={keikiLayout} className="image-full" alt="keiki layout" />
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-half">
                <div className="specs-row">
                  <p>Length</p>
                  <p>12.87 m</p>
                </div>
                <div className="specs-row">
                  <p>Width</p>
                  <p>4.18 m</p>
                </div>
                <div className="specs-row">
                  <p>Draft</p>
                  <p>2.17 m</p>
                </div>
                <div className="specs-row">
                  <p>Air Draft</p>
                  <p>18.33 m</p>
                </div>
                <div className="specs-row">
                  <p>Year Built</p>
                  <p>2021</p>
                </div>
                <div className="specs-row">
                  <p>Number of Cabins</p>
                  <p>3</p>
                </div>
                <div className="specs-row">
                  <p>Heads</p>
                  <p>2 with separated shower</p>
                </div>
                <div className="specs-row">
                  <p>Sleeping Capacity</p>
                  <p>8</p>
                </div>
                <div className="specs-row">
                  <p>Cabin Configuration</p>
                  <p>1 Owners + 2 Double</p>
                </div>
              </div>
              <div className="column-one-half">
                <div className="specs-row">
                  <p>Water Tanks</p>
                  <p>550l Fresh Water in 2 tanks</p>
                </div>
                <div className="specs-row">
                  <p>Fuel Tank</p>
                  <p>200l Diesel</p>
                </div>
                <div className="specs-row">
                  <p>Black water holding</p>
                  <p>Tank for each head</p>
                </div>
                <div className="specs-row">
                  <p>Engine</p>
                  <p>45HP Yanmar</p>
                </div>
                <div className="specs-row">
                  <p>Bow Thruster</p>
                  <p>Yes, folding</p>
                </div>
                <div className="specs-row">
                  <p>Webasto Heating</p>
                  <p>Yes</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Testimonials
          className="brown"
          /> */}
        <BookAdventure
          filter="keiki"
          id="book"
          />
        <Footer />
      </>
    )
  }
}