import React from 'react'

import { Button } from './elements/all-elements'

import OurStory from './sections/OurStory'
import Footer from './sections/Footer'

import OurFleet from './sections/OurFleet'
import BookAdventure from './sections/BookAdventure'
import Header from './sections/Header'
import Testimonials from './sections/Testimonials'

import mainImg from './img/nalu/large/2.jpg'

export default class HomePage extends React.Component {
  render() {
    const headerImage = mainImg
    return (
      <>
        <Header
          headerImage={headerImage}
          className="header-image-background">
          <div className="grid-row">
            <div className="column-one-half">
              <h1>Your sailing holiday, in true owners style</h1>
              <div className="subtitle">
                Experience the Adriatic in a unique way. Sail along
                the coast, explore historic cities, anchor at pristine
                bays aboard sailing yachts like no other. 
              </div>
              <Button link="/#book" className="button-rounded button-size-m">Request an offer</Button>
            </div>
          </div>
        </Header>
        <main>
          <OurStory />
          <OurFleet />
          <BookAdventure id="book" />
          <Testimonials className="brown" />
        </main>
        <Footer />
      </>
    )
  }
}