import React from 'react'
import Calendar from '../components/Calendar'

export default class BookAdventure extends React.Component {
  render() {
    return(
      <section {...this.props}>
        <div className="container">
          <div className="grid-row">
            <div className="column-full center">
              <h2>Let's book your great adventure!</h2>
            </div>
          </div>
          <Calendar
            filter={this.props.filter}
            />
        </div>
      </section>
    )
  }
}
