import React from 'react'
import Header from './sections/Header'
import Footer from './sections/Footer'

export default class ContactPage extends React.Component {
  render() {
    return (
      <>
        <Header />
        <section>
          <div className="container">
            <div className="grid-row">
              <div className="column-one-third">
                <h2>Welcome to our safe harbor</h2>
                <h3>ACI Marina Pomer</h3>
                <p>
                  Our boats are all based in <a href="https://goo.gl/maps/RNYYdrQRcJ73j7u69">ACI Marina
                  Pomer (Pomer 52100, Croatia)</a>, near Pula where they are taken care of by lovely and
                  friendly crew of <a href="https://ultra-sailing.hr">Ultra Sailing</a>.
                </p>
              </div>
              <div className="column-two-thirds">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d132772.15375886884!2d13.843687288482254!3d44.894454169342694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47632b81b6581819%3A0x6d543d8ef365bb19!2sACI%20Marina%20Pomer!5e0!3m2!1sen!2ssk!4v1618165331777!5m2!1sen!2ssk"
                  title="google map to ACI Marina Pomer"
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    borderRadius: '0.5em'
                  }} allowFullScreen="" loading="lazy"></iframe>
              </div>
            </div>
          </div>
        </section>
        <section className="brown">
          <div className="container">
            <div className="grid-row">
              <div className="column-one-half">
                <h2>Let's get in touch</h2>
                <p>
                  We will gladly answer any of your questions,
                  listen to your inquiries or reviews about how
                  you enjoyed your trip on one of our boats
                </p>
                <p>
                  <strong>
                    You can contact us through <a href="https://instagram.com/sailingnalu">Instagram</a> or 
                    via email at <a href="mailto:hello@nalusailing.com">hello@nalusailing.com</a>.
                  </strong>
                </p>
              </div>
              <div className="column-one-sixth person-profile person-profile-jakub">
                <div className="person-profile-image"></div>
                <p><strong>Jakub</strong></p>
              </div>
              <div className="column-one-sixth person-profile person-profile-marek">
                <div className="person-profile-image"></div>
                <p><strong>Marek</strong></p>
              </div>
              <div className="column-one-sixth person-profile person-profile-ondrej">
                <div className="person-profile-image"></div>
                <p><strong>Ondrej</strong></p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="grid-row">
              <div className="column-full">
                <h2>Our billing information</h2>
              </div>
            </div>
            <div className="grid-row">
              <div className="column-one-third">
                <p>
                  <strong>Nalu Sailing d.o.o.</strong><br />
                  Pavlinska ulica 5<br />
                  42000 Varaždin
                </p>
              </div>
              <div className="column-one-third">
                <p>
                  OIB: 29977583044<br />
                  MBS: 070175628<br />
                  VAT ID: HR29977583044<br />
                </p>
              </div>
              <div className="column-one-third">
                <p>
                  IBAN: HR9524020061100962577<br />
                  SWIFT: ESBCHR22<br />
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </>
    )
  }
}