import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import HomePage from './HomePage'
import NaluPage from './NaluPage'
import KeikiPage from './KeikiPage'
import ContactPage from './ContactPage'



import './styles/main.sass'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentPage: 'home'
    }
  }

  componentDidUpdate() {
    window.gtag('event', 'page_view')
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={HomePage}/>
        <Route exact path="/contact" component={ContactPage}/>
        <Route path="/nalu" component={NaluPage}/>
        <Route path="/keiki" component={KeikiPage}/>
      </Switch>
    )
  }
  
}

export default withRouter(App)