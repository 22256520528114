import React from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faStar } from '@fortawesome/free-solid-svg-icons'

// import photo1 from '../img/us/ondrej.jpeg'

export default class Testimonials extends React.Component {
  constructor(props) {
    super(props)
    this.countTestimonials = 3
    this.state = {
      marginLeft: 0
    }
  }
  backwards() {
    if(this.state.marginLeft !== 0) {
      this.setState({ marginLeft: this.state.marginLeft + 31 })
    }
  }

  forwards() {
    if(this.state.marginLeft !== ((this.countTestimonials - 1) * (-31))) {
      this.setState({ marginLeft: this.state.marginLeft - 31 })
    }
  }
  
  render() {
    return(
      <section {...this.props}>
        <div className="container" id="testimonials">
          <div className="grid-row">
            <div className="column-one-half">
              <h2>Testimonials</h2>
            </div>
            <div className="column-one-half column-align-right">
                <button
                  onClick={this.backwards.bind(this)}
                  className="button-transparent">&#x27F5;</button>
                <button
                  onClick={this.forwards.bind(this)}
                  className="button-transparent">&#x27F6;</button>
            </div>
          </div>
          <div className="grid-row">
            <div className="column-full testimonials-column">
              <div className="testimonials-container" style={{
                marginLeft: (this.state.marginLeft + "em")
              }}>
                <div className="testimonials-box">
                  <h3>Great time</h3>
                  <p>
                  “We have had such a great time with Nalu 🤩 😍 😎 And so much fun 😅 😅 A lot of thanks from our whole crew.”
                  </p>
                  <p>
                    <span><strong>Martina, Germany</strong></span>
                   </p>
                </div>
                <div className="testimonials-box">
                  <h3>Nalu is a beauty</h3>
                  <p>
                    “First of all, let me just thank you all for making this boat available, because it is a beauty. It is the nicest ship we have ever been on, and that’s a big thing to say, because we have sailed a few 🙂 I hope that this boat will give many more people pure happiness just as it gives us. I hope we come across this beauty again...”
                  </p>
                  <p>
                    <span><strong>Viktoria, Czech Republic</strong></span>
                   </p>
                </div>
                
                <div className="testimonials-box">
                  <h3>Awesome experience</h3>
                  <p>
                  “We had a lovely time and enjoyed our holiday.”
                  </p>
                  <p>
                    <span><strong>Maxine, Germany</strong></span>
                   </p>
                </div>
                

                {/* <div className="testimonials-box">
                  <p className="testimonials-stars">
                    <span className="yellow"><FontAwesomeIcon icon={faStar} /></span>
                    <span className="yellow"><FontAwesomeIcon icon={faStar} /></span>
                    <span className="yellow"><FontAwesomeIcon icon={faStar} /></span>
                    <span className="yellow"><FontAwesomeIcon icon={faStar} /></span>
                  </p>
                  <h3>We had wonderful time!</h3>
                  <p>
                  “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.”
                  </p>
                  <p>
                    <img src={photo1} alt="Ondrej" />
                    <span><strong>Michal</strong></span>
                   </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
