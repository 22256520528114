import React from 'react'
import { isMobile } from 'react-device-detect'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpandArrowsAlt, faTimes } from  '@fortawesome/free-solid-svg-icons'

export default class Home extends React.Component {
  gen4() {
    return Math.random().toString(16).slice(-4)
  }
  getUniqueID(prefix) {
    return (prefix || '').concat([
      this.gen4(),
      this.gen4(),
      this.gen4(),
      this.gen4(),
      this.gen4(),
      this.gen4(),
      this.gen4(),
      this.gen4()
    ].join(''))
  }
  constructor(props) {
    super(props)

    this.images = React.Children.toArray(this.props.children)
    this.id = this.getUniqueID()
    this.state = {
      image: 0,
      fullscreen: false,
      fullscreenImage: 0
    }
  }

  keyDownFS(e) {
    if (this.state.fullscreen) {
      e.preventDefault()

      switch (e.key) {
        case "ArrowLeft":
          this.backwardsFS()
          break
        case "ArrowRight":
          this.forwardsFS()
          break
        case "Escape":
          this.closeFS()
          break
        default:
          break
      }
    }
  }

  backwards() {
    if (this.state.image === 0) {
      this.setState({
        image: this.images.length - 1
      })
    } else {
      this.setState({
        image: this.state.image - 1
      })
    }
  }

  backwardsFS() {
    if (this.state.fullscreenImage === 0) {
      this.setState({
        fullscreenImage: this.images.length - 1
      })
    } else {
      this.setState({
        fullscreenImage: this.state.fullscreenImage - 1
      })
    }
  }

  forwards() {
    if (this.state.image === (this.images.length - 1)) {
      this.setState({
        image: 0
      })
    } else {
      this.setState({
        image: this.state.image + 1
      })
    }
  }
  
  forwardsFS() {
    if (this.state.fullscreenImage === (this.images.length - 1)) {
      this.setState({
        fullscreenImage: 0
      })
    } else {
      this.setState({
        fullscreenImage: this.state.fullscreenImage + 1
      })
    }
  }

  handleTouchStart(e) {
    this.touchStart = e.targetTouches[0].clientX
  }

  handleTouchMove(e) {
    this.touchEnd = e.targetTouches[0].clientX
  }

  handleTouchEnd() {
    if (this.touchStart - this.touchEnd > 50) {
      this.forwards()
    }

    if (this.touchStart - this.touchEnd < -50) {
      this.backwards()
    }
  }

  closeFS() {
    this.setState({fullscreen: false})
  }

  closeFSEvent(event) {
    if (event.target.id === 'buttonLeft-'+this.id || event.target.id === 'buttonRight-'+this.id) {
      return
    }
    this.closeFS()
  }

  render() {
    return (
      <div
        onKeyDown={this.keyDownFS.bind(this)}
        >
        {(this.state.fullscreen) ? (
          <div
            className="inline-image-gallery-fullscreen"
            onClick={this.closeFSEvent.bind(this)}
            >
            <div className="inline-image-gallery-fullscreen-closeButton">
              <button
                className="button-transparent">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="inline-image-gallery-fullscreen-button">
              <button
                id={'buttonLeft-'+this.id}
                onClick={this.backwardsFS.bind(this)}
                className="button-transparent">&#x27F5;</button>
            </div>
            
            <div className="inline-image-gallery-fullscreen-image">
              <img
                src={this.images[this.state.fullscreenImage].props.largeimage}
                alt={this.images[this.state.fullscreenImage].props.alt} />
            </div>
            <div className="inline-image-gallery-fullscreen-button">
              <button
                id={'buttonRight-'+this.id}
                onClick={this.forwardsFS.bind(this)}
                className="button-transparent">&#x27F6;</button>
            </div>
            
          </div>
        ) : null}
        <div 
          className="inline-image-gallery"
          onTouchStart={this.handleTouchStart.bind(this)}
          onTouchMove={this.handleTouchMove.bind(this)}
          onTouchEnd={this.handleTouchEnd.bind(this)}
          >
          {(this.props.fullscreenEnabled && !isMobile) ? (
            <div className="inline-image-gallery-controls-left">
              <button
                className="button-transparent"
                onClick={() => {
                  this.setState({fullscreenImage: this.state.image})
                  this.setState({fullscreen: true})
                }}>
                <FontAwesomeIcon icon={faExpandArrowsAlt} />
              </button>
            </div>
          ) : null}
            
          {(this.images.length > 1) ? (
            <div className="inline-image-gallery-controls-right">
            

            {(isMobile) ? null : (
              <button
                onClick={this.backwards.bind(this)}
                className="button-transparent">&#x27F5;</button>
            )}
            {this.state.image + 1} / {this.images.length}
            {(isMobile) ? null : (
              <button
                onClick={this.forwards.bind(this)}
                className="button-transparent">&#x27F6;</button>
            )}
          </div>
          ) : null}
          {this.images[this.state.image]}
          <div className="bottom-shadow" />
        </div>
      </div>
    )
  }
}