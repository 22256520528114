import React from 'react'

import { Button } from '../elements/all-elements'

import InlineImageGallery from '../components/InlineImageGallery'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnchor, faArrowsAltH, faUserFriends, faBed, faBath } from '@fortawesome/free-solid-svg-icons'

import ne1 from '../img/nalu/e1.jpg'
import ne2 from '../img/nalu/e2.jpg'
import ne4 from '../img/nalu/e4.jpg'
import ne6 from '../img/nalu/e6.jpg'
import ne7 from '../img/nalu/e7.jpg'
import ni1 from '../img/nalu/i1.jpg'
import ni2 from '../img/nalu/i2.jpg'
import ni3 from '../img/nalu/i3.jpg'
import ni4 from '../img/nalu/i4.jpg'
import ni5 from '../img/nalu/i5.jpg'
import ni6 from '../img/nalu/i6.jpg'
import ni7 from '../img/nalu/i7.jpg'
import ni8 from '../img/nalu/i8.jpg'
import ni9 from '../img/nalu/i9.jpg'
import ni10 from '../img/nalu/i10.jpg'
import ni11 from '../img/nalu/i11.jpg'

import ke1 from '../img/keiki/e1.jpg'
import ke2 from '../img/keiki/e2.jpg'
import ke3 from '../img/keiki/e3.jpg'
import ke4 from '../img/keiki/e4.jpg'
import ke5 from '../img/keiki/e5.jpg'
import ke6 from '../img/keiki/e6.jpg'
import ki1 from '../img/keiki/i1.jpg'
import ki2 from '../img/keiki/i2.jpg'
import ki3 from '../img/keiki/i3.jpg'
import ki4 from '../img/keiki/i4.jpg'
import ki5 from '../img/keiki/i5.jpg'
import ki6 from '../img/keiki/i6.jpg'
import ki7 from '../img/keiki/i7.jpg'
import ki8 from '../img/keiki/i8.jpg'
import ki9 from '../img/keiki/i9.jpg'
import ki10 from '../img/keiki/i10.jpg'
import ki11 from '../img/keiki/i11.jpg'
import ki12 from '../img/keiki/i12.jpg'

import nlayout from '../img/nalu/layout.png'
import klayout from '../img/keiki/layout.png'

export default class OurFleet extends React.Component {
  render() {
    return(
      <section className="brown">
        <div className="container" id="ourfleet">
          <div className="grid-row grid-row-align-center">
            <div className="column-two-fifths">
              <h2>Our Fleet,<br />Your Comfort</h2>
            </div>
            <div className="column-three-fifths">
              <p>
                Our yachts were selected and equipped based on years of
                experience at sea, to provide comfort, performance and smart features.
                We spend many weeks sailing, living and working on them each year.
                We make sure they are properly maintained and ready to welcome you
                at every moment!
              </p>
            </div>
          </div>
          <div className="grid-row">
            <div className="column-two-fifths">
              <h3>Nalu</h3>
              <p>
                Our first yacht in the fleet, Nalu, is an owner's version of Beneteau
                Oceanis 46.1. With 4 cabins and a large saloon, she can host 10 guests.
                Nalu, european yacht of the year in 2019, is a class leading sailboat
                in comfort, internal volume and sailing abilities.
              </p>
              <div className="button-group">
                <Button 
                  className="button-rounded button-white button-size-m"
                  link="/nalu">
                  Discover Nalu
                </Button>
                <Button 
                  className="button-rounded button-size-m"
                  link="/#book">
                  Request an offer
                </Button>
              </div>
            </div>
            <div className="column-three-fifths">
              <InlineImageGallery>
                <img src={ne1} alt="exterior of nalu" />
                <img src={nlayout} alt="layout of keiki" />
                <img src={ne2} alt="exterior of nalu" />
                <img src={ne4} alt="exterior of nalu" />
                <img src={ne6} alt="exterior of nalu" />
                <img src={ne7} alt="exterior of nalu" />
                <img src={ni1} alt="interior of nalu" />
                <img src={ni2} alt="interior of nalu" />
                <img src={ni3} alt="interior of nalu" />
                <img src={ni4} alt="interior of nalu" />
                <img src={ni5} alt="interior of nalu" />
                <img src={ni6} alt="interior of nalu" />
                <img src={ni7} alt="interior of nalu" />
                <img src={ni8} alt="interior of nalu" />
                <img src={ni9} alt="interior of nalu" />
                <img src={ni10} alt="interior of nalu" />
                <img src={ni11} alt="interior of nalu" />
              </InlineImageGallery>
            </div>
            
          </div>
          <div className="grid-row">
            <div className="column-full">
              <ul className="icon-line">
                <li className="icon-line-item">                    
                  <FontAwesomeIcon icon={faAnchor} />
                  2020
                </li>
                <li className="icon-line-item">
                  <FontAwesomeIcon icon={faArrowsAltH} />
                  14.6m long
                </li>
                <li className="icon-line-item">
                  <FontAwesomeIcon icon={faUserFriends} />
                  10 guests
                </li>
                <li className="icon-line-item">
                  <FontAwesomeIcon icon={faBed} />
                  4 cabins
                </li>
                <li className="icon-line-item">
                  <FontAwesomeIcon icon={faBath} />
                  2 heads + shower
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="grid-row">
            <div className="column-two-fifths">
              <h3>Keiki</h3>
              <p>
                Our recent addition, Keiki, is an owner's version of Beneteau
                Oceanis 40.1. She can host up to 8 guests in 3 large cabins
                and spacious saloon. A 2021 SAIL winner, she has an entirely
                new boat design in the 12m sailboat range. Keiki offers
                unparalleled levels of comfort and sailing potential.
              </p>
              <div className="button-group">
                <Button 
                  className="button-rounded button-white button-size-m"
                  link="/keiki">
                  Discover Keiki
                </Button>
                <Button 
                  className="button-rounded button-size-m"
                  link="/#book">
                  Request an offer
                </Button>
              </div>
            </div>
            <div className="column-three-fifths">
              <InlineImageGallery>
                <img src={ke1} alt="exterior of keiki" />
                <img src={klayout} alt="layout of keiki" />
                <img src={ke2} alt="exterior of keiki" />
                <img src={ke3} alt="exterior of keiki" />
                <img src={ke4} alt="exterior of keiki" />
                <img src={ke5} alt="exterior of keiki" />
                <img src={ke6} alt="exterior of keiki" />
                <img src={ki1} alt="interior of keiki" />
                <img src={ki2} alt="interior of keiki" />
                <img src={ki3} alt="interior of keiki" />
                <img src={ki4} alt="interior of keiki" />
                <img src={ki5} alt="interior of keiki" />
                <img src={ki6} alt="interior of keiki" />
                <img src={ki7} alt="interior of keiki" />
                <img src={ki8} alt="interior of keiki" />
                <img src={ki9} alt="interior of keiki" />
                <img src={ki10} alt="interior of keiki" />
                <img src={ki11} alt="interior of keiki" />
                <img src={ki12} alt="interior of keiki" />
              </InlineImageGallery>
            </div>
          </div>
          <div className="grid-row">
            <div className="column-full">
                <ul className="icon-line">
                  <li className="icon-line-item">                    
                    <FontAwesomeIcon icon={faAnchor} />
                    2021
                  </li>
                  <li className="icon-line-item">
                    <FontAwesomeIcon icon={faArrowsAltH} />
                    12.9m long
                  </li>
                  <li className="icon-line-item">
                    <FontAwesomeIcon icon={faUserFriends} />
                    8 guests
                  </li>
                  <li className="icon-line-item">
                    <FontAwesomeIcon icon={faBed} />
                    3 cabins
                  </li>
                  <li className="icon-line-item">
                    <FontAwesomeIcon icon={faBath} />
                    2 heads
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </section>
    )
  }
}
