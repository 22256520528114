import React from 'react'

export default class PageSwitcher extends React.Component {
  changePage(href) {
    const regex = /.+#([a-zA-Z-]+)/
    const result = regex.exec(href)
    
    this.props.history.push(href)
    if (result && result[1]) {
      // scroll!
      setTimeout(function() {
        const element = document.getElementById(result[1])
        if (element) {
          document.documentElement.style.scrollBehavior = 'smooth'
          window.scrollTo(0, element.getBoundingClientRect().top + window.scrollY)
          document.documentElement.style.scrollBehavior = 'auto'
        }
      }, 10)
      return "pageLocation"
    }
    return "page"
  }
}