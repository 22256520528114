import React from 'react'

import { isMobileOnly } from 'react-device-detect'

import { APIROOT } from '../api-config'

import { Button } from '../elements/all-elements'


export default class Calendar extends React.Component {
  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ]

  constructor(props) {
    super(props)

    // according to this date the calendar is shown
    // if next year is needed (e.g. it is november)
    // add one year.
    this.year = '2023-01-01T03:24:00'

    const d = new Date(this.year)

    this.prices = null
    
    this.state = {
      filter: this.props.filter||null,
      bookedDates: null,
      hoveredWeek: null,
      selectedWeeks: [],
      book: {
        name: "",
        email: "",
        sending: false,
        opened: null,
        sent: null,
        emailError: false,
      },
      firstMonth: new Date(d.getFullYear(), ((d.getMonth() > 4) ? d.getMonth() : 5), 1)
    }
  }

  componentDidMount() {
    this.getBookedDates()
    this.getPrices()
  }

  openBooking(boatID) {
    let b = this.state.book
    b.opened = boatID
    this.setState({ book: b })
  }

  updateInput(input, e) {
    let b = this.state.book
    b[input] = e.target.value
    this.setState({book: b})
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  async sendBooking(boatID, e) {
    let b = this.state.book

    if (!this.validateEmail(this.state.book.email)) {
      b.emailError = true
      this.setState({book: b})  
      return
    }

    b.emailError = false
    b.sending = true
    this.setState({book: b})

    const sd = new Date(
      (new Date("Jan 01, " + (new Date(this.year)).getFullYear() + " 01:00:00")).getTime() +
      604800000 * (this.state.selectedWeeks[0] - 2))
    const ed = new Date(
      (new Date("Jan 01, " + (new Date(this.year)).getFullYear() + " 01:00:00")).getTime() +
      604800000 * (this.state.selectedWeeks[this.state.selectedWeeks.length - 1] - 1))


    const body = {
      name: this.state.book.name,
      email: this.state.book.email,
      from: this.getDateString(sd),
      until: this.getDateString(ed),
      boatID: boatID
    }

    const response = await fetch(APIROOT + "/book", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }).catch(err => {
      console.error(err)
    })

    if (!response || response.status !== 200) {
      return 
    }

    b.sending = false
    b.sent = true
    this.setState({book: b})
    setTimeout(function() {
      b.sent = false
      b.opened = null
      this.setState({book: b})
    }.bind(this), 1500)
  }

  getDateString(date) {
    const dateString = date.getFullYear() + "-" +
        ('0' + (date.getMonth() + 1)).slice(-2) + "-" +
        ('0' + (date.getDate())).slice(-2)

    return dateString
  }

  async getBookedDates() {
    const response = await fetch(APIROOT + "/bookedDates", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }).catch(err => {
      console.error(err)
    })

    if (!response || response.status !== 200) {
      return []
    }

    const data = await response.json()
    this.setState({
      bookedDates: data
    })
  }

  async getPrices() {
    const response = await fetch(APIROOT + "/prices", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).catch(err => {
      console.error(err)
    })
    
    if (!response || response.status !== 200) {
      return null
    }

    const data = await response.json()
    this.prices = data
  }

  monthBackward() {
    this.setState({firstMonth: new Date(this.state.firstMonth.getFullYear(), this.state.firstMonth.getMonth() - 1, 1)})
  }
  monthForward() {
    this.setState({firstMonth: new Date(this.state.firstMonth.getFullYear(), this.state.firstMonth.getMonth() + 1, 1)})
  }

  // get week number
  getWeek(date) {
    var onejan = new Date(date.getFullYear(), 0, 1);
    return Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
  }

  selectWeek(week) {
    let s = this.state.selectedWeeks
    // if there is adjacent week
    if (s.indexOf(week + 1) !== -1 || s.indexOf(week - 1) !== -1) {
      s.push(week)
      s.sort()
    } else {
      s = [week]
    }
    
    this.setState({selectedWeeks: s, hoveredWeek: null})

    // if the week ends in next month, show also next month
    const t = (new Date("Jan 01, " + (new Date(this.year)).getFullYear() + " 01:00:00")).getTime() +
      604800000 * (week - 1)
    const fd = new Date(t - 604800000 + 86400000)
    const ld = new Date(t)
    

    if ((this.state.firstMonth.getMonth() + 1) < ld.getMonth()) {
      this.monthForward()
    }

    if ((this.state.firstMonth.getMonth()) > fd.getMonth()) {
      this.monthBackward()
    }
  }

  unselectWeek(week) {
    const s = this.state.selectedWeeks
    if (s.indexOf(week) === -1)
      return

    s.splice(s.indexOf(week), 1)
    this.setState({selectedWeeks: s})
  }

  hoverWeek(week) {
    this.setState({hoveredWeek: week})
  }
  unhoverWeek() {
    this.setState({hoveredWeek: null})
  }

  getCalendarItems(month) {
    // array for calendar items
    const items = []


    // first day of requested month
    const firstOfMonth = new Date(month)
    // first day of week of current month
    const firstDayOfMonth = firstOfMonth.getDay() || 7
    
    // last day of requested month
    const lastOfMonth = new Date(firstOfMonth.getDate(), (firstOfMonth.getMonth() + 1), 0);
    // number of weeks in requested month (=== number of lines)
    const noOfWeeks = Math.ceil((firstDayOfMonth + lastOfMonth.getDate()) / 7)

    // day iterator for calendar
    const dayIterator = new Date(month)

    // iterate through weeks
    for(let i = 0; i < noOfWeeks; i++) {
      items[i] = []

      // iterate through days of week
      for (let b = 0; b < 7; b++) {
        let text = ""
        let classes = []

        // get current date as a string
        // in order to be able to compare it with API output
        const dateString = dayIterator.getFullYear() + "-" +
          ('0' + (dayIterator.getMonth() + 1)).slice(-2) + "-" +
          ('0' + (dayIterator.getDate())).slice(-2)

        // first or last empty days
        if ((i === 0 && (b < (firstDayOfMonth - 1))) || 
            (i > 0
              && (dayIterator.getMonth() > firstOfMonth.getMonth() || dayIterator.getFullYear() > firstOfMonth.getFullYear()))) {
          items[i].push(<td key={b}></td>)
          continue
        }
        
        // get day number to be outputted
        text = dayIterator.getDate()

        // get last saturday (or today if today is saturday)
        const saturday = new Date(dayIterator)
        if (dayIterator.getDay() !== 6) {
          saturday.setDate(saturday.getDate() - saturday.getDay() - 1)
        }

        // check whether previous saturday is in future
        const future = saturday > new Date()
        

        // check booked dates whether today is free
        let isBookedDate = false
        let countBookedDate = 0
        // if any boat is free
        if (this.state.filter === null) {
          for(const [, value] of this.state.bookedDates.entries()) {
            if (value.bookedDates.indexOf(dateString) !== -1) {
              countBookedDate++
            }
          }

          // if number of found booked dates equals number of boats
          // (than both of them are already booked)
          if (countBookedDate === this.state.bookedDates.length) {
            isBookedDate = true
          }
        // TODO: if one of the boats (according to filter) is free
        } else {
          if (this.state.bookedDates.find(x => x.boatID === this.state.filter).bookedDates.indexOf(dateString) !== -1)
            isBookedDate = true
        }

        // current week
        const w = this.getWeek(dayIterator)
        const day = dayIterator.getDay()
        const curWeek = (day === 6) ? (w + 1) : w

        const isSelectedWeek = this.state.selectedWeeks.indexOf(curWeek) !== -1
        const isSelectable = !isSelectedWeek && future && !isBookedDate
        const isUnselectable = isSelectedWeek && (this.state.selectedWeeks.indexOf(curWeek - 1) === -1 || this.state.selectedWeeks.indexOf(curWeek + 1) === -1)

        const isHoveredWeek = this.state.hoveredWeek === curWeek
        const isHoverable = (!isSelectedWeek || (isSelectedWeek && isUnselectable)) && future && !isBookedDate

        if (!future || isBookedDate) {
          classes.push("calendar-disabled")
        }

        if (day === 6) {
          classes.push("calendar-saturday")
        }

        if (isHoverable) {
          classes.push("calendar-hoverable")
        }

        if (isHoveredWeek) {
          classes.push("calendar-hovered-week")

          // last day of week
          if (day === 5 &&
            // do not add class if next week is selected
            this.state.selectedWeeks.indexOf(curWeek + 1) === -1) {
            classes.push("calendar-last-day-hovered")
          }

          // if previous week is selected add class
          if (day === 6 && this.state.selectedWeeks.indexOf(curWeek - 1) !== -1) {
            classes.push("calendar-saturday-selected-previousweek")
          }
        }

        if (isSelectedWeek) {
          classes.push("calendar-selected-week")
          if (day === 5 &&
            // if next week is hovered do not add class 
            this.state.hoveredWeek !== (curWeek + 1) &&
            // if next week is selected do not add class
            this.state.selectedWeeks.indexOf(curWeek + 1) === -1
            ) {
            classes.push("calendar-last-day-selected")
          }

          if (day === 6) {
            // if previous week is selected add class
            if (this.state.selectedWeeks.indexOf(curWeek - 1) !== -1) {
              classes.push("calendar-saturday-selected-previousweek")
            }
            if (this.state.hoveredWeek === (curWeek - 1)) {
              classes.push("calendar-saturday-hovered-previousweek")
            }
          }
        }

        

        // nonselectable day
        let span = (
          <td
            key={b}
            className={classes.join(" ")}
            onMouseOver={(isHoverable) ? this.hoverWeek.bind(this, curWeek) : null}
            onMouseLeave={(isHoverable) ? this.unhoverWeek.bind(this) : null}
            onClick={(isSelectable) ? this.selectWeek.bind(this, curWeek) : ((isUnselectable) ? this.unselectWeek.bind(this, curWeek) : null)}
            >
            <span>{text}</span>
          </td>
        )

        // add a calendar item
        items[i].push(span)

        // prepare for next day
        dayIterator.setDate(dayIterator.getDate() + 1)
      }
    }

    return items
  }

  renderMonth(month, order) {
    const items = this.getCalendarItems(month)
    // first day of requested month
    const firstOfMonth = new Date(month)    

    // table containing the calendar
    return (
      <>
        {(order === 0 && firstOfMonth.getMonth() >= 1 && isMobileOnly) ? (
          <button
            className="button-transparent calendar-button-mobile"
            onClick={this.monthBackward.bind(this)}>
              &#9650;

            </button>
        ) : null }
        <table cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th colSpan={7}>
                {(order === 0 && firstOfMonth.getMonth() >= 1 && !isMobileOnly) ? (
                  <button
                    className={"button-transparent button-left"}
                    onClick={this.monthBackward.bind(this)}
                    >&#60;</button>
                ) : null}
                {this.monthNames[firstOfMonth.getMonth()]}
                {(order === 1  && firstOfMonth.getMonth() < 11 && !isMobileOnly) ? (
                  <button
                    className={"button-transparent button-right"}
                    onClick={this.monthForward.bind(this)}
                    >&#62;</button>
                ) : null}
              </th>
            </tr>
            <tr>
              <th>mon</th>
              <th>tue</th>
              <th>wed</th>
              <th>thu</th>
              <th>fri</th>
              <th>sat</th>
              <th>sun</th>
            </tr>
          </thead>
          <tbody>
            {items.map((value, index) => {
              return (
                <tr key={index}>
                  {value}
                </tr>
              )
            })}
          </tbody>
        </table>
        {(order === 1  && firstOfMonth.getMonth() < 11 && isMobileOnly) ? (
          <button
            className="button-transparent calendar-button-mobile"
            onClick={this.monthForward.bind(this)}>
            &#9660;
          </button>
        ) : null }
      </>
    )
  }

  showResults() {
    if (this.state.selectedWeeks.length === 0) {
      return null
    }

    const sd = new Date(
      (new Date("Jan 01, " + (new Date(this.year)).getFullYear() + " 01:00:00")).getTime() +
      604800000 * (this.state.selectedWeeks[0] - 2))
    const ed = new Date(
      (new Date("Jan 01, " + (new Date(this.year)).getFullYear() + " 01:00:00")).getTime() +
      604800000 * (this.state.selectedWeeks[this.state.selectedWeeks.length - 1] - 1))
    
    let boats = {}
    let countAvailableBoats = 0

    let boatArray = [this.state.filter]
    if (this.state.filter === null) {
      boatArray = ['nalu', 'keiki']
    }

    for(const [,v] of boatArray.entries()) {
      boats[v] = {
        available: true,
        name: v,
        price: 0
      }
    }

    for(;sd < ed; sd.setDate(sd.getDate() + 1)) {
      const dateString = sd.getFullYear() + "-" +
        ('0' + (sd.getMonth() + 1)).slice(-2) + "-" +
        ('0' + (sd.getDate())).slice(-2)
      
      if(sd.getDay() === 6) {
        for(const [,v] of this.prices.entries()) {
          const p = v.weekPrices.find(x => x.saturday === dateString)
          if (p && boats[v.boatID]) {
            boats[v.boatID].price += p.price
          }
        }
      }
      
      for(const [,v] of this.state.bookedDates.entries()) {
        if (v.bookedDates.indexOf(dateString) !== -1 && boats[v.boatID]) {
          boats[v.boatID].available = false
        }
      }
    }


    return (
      <>
        {Object.entries(boats).map(([key, value]) => {
          if (value.available) {
            countAvailableBoats++
            return (
              <div 
                key={key}
                className="calendar-yachtline">
                <div>
                  <div className={"calendar-yachtline-boatimage " + key} />
                  <h3>{key} Yacht</h3>
                  <p className="price">{value.price.toLocaleString('en-US', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0
})}</p>
                </div>
                
                {(this.state.book.opened === key) ? (
                  <>
                    {(this.state.book.sent) ? (
                      <div><p>Your request was successfully sent!</p></div>
                    ) : (
                      <div className="calendar-book-input">
                      <input
                        type="text"
                        value={this.state.book.name}
                        onChange={this.updateInput.bind(this, "name")}
                        autoComplete="name"
                        disabled={this.state.book.sending}
                        placeholder="Your Name"
                        />
                      <input
                        value={this.state.book.email}
                        onChange={this.updateInput.bind(this, "email")}
                        autoComplete="email"
                        type="email"
                        disabled={this.state.book.sending}
                        placeholder="Your Email"
                        style={{borderColor: (this.state.book.emailError) ? "red" : null}}
                        />
                      <button
                        className={"button-rounded button-size-m" + ((this.state.book.sending) ? " button-sending": "")}
                        onClick={this.sendBooking.bind(this, key)}
                        disabled={(this.state.book.name === "" || this.state.book.email === "" || this.state.book.sending)}>
                        Send request
                      </button>
                    </div>
                    )}
                  </>
                ) : (
                <div className="button-group">
                  <Button
                    className="button-rounded button-white"
                    link={"/" + key}
                    >Discover {key}
                  </Button>
                  <button
                    className="button-rounded"
                    onClick={this.openBooking.bind(this, key)}>Request an offer</button>
                </div>
                )}
              </div>
            )
          }
          return null
        })}
        {(countAvailableBoats === 0) ? (
          <p>No yachts available through all of the selected weeks.</p>
        ): null}
      </>

    )
    
  }

  filterData(e) {
    const f = e.target.getAttribute("data-filter") 
    this.setState({
      filter: f,
      selectedWeeks: [],
      hoveredWeek: null
    })
  }

  render() {
    return (
      <>
        <div className="grid-row">
            <div className="column-full center">
              <div className="calendar-filter">
                <button 
                  data-filter={null}
                  className={(this.state.filter === null) ? "selected" : ""}
                  onClick={this.filterData.bind(this)}
                  >Both</button>
                <button
                  data-filter="nalu"
                  className={(this.state.filter === "nalu") ? "selected" : ""}
                  onClick={this.filterData.bind(this)}
                  >Nalu</button>
                <button
                  data-filter="keiki"
                  className={(this.state.filter === "keiki") ? "selected" : ""}
                  onClick={this.filterData.bind(this)}
                  >Keiki</button>
              </div>
            </div>
        </div>
        <div className="grid-row">
          <div className="column-full center">
            <div className="calendar">
              {(this.state.bookedDates === null) ? null : (
                <>
                  <div>{this.renderMonth(this.state.firstMonth, 0)}</div>
                  <div>{this.renderMonth(new Date(this.state.firstMonth.getFullYear(), (this.state.firstMonth.getMonth() + 1), 1), 1)}</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="grid-row">
          <div className="column-full center">
            {this.showResults()}
          </div>
        </div>
      </>
    )
  }
}