import React from 'react'
import PageSwitcher from './PageSwitcher'

import { withRouter } from 'react-router'

class A extends PageSwitcher {
  onClick(e) {
    e.preventDefault()
    const href = e.currentTarget.getAttribute("href")
    const navigatedTo = this.changePage(href)
    if (navigatedTo === "page") {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return (
      <a
        onClick={this.onClick.bind(this)}
        href={this.props.href}
        className={this.props.className}
        >
        {this.props.children}
      </a>
    )

  }
}

export default withRouter(A)