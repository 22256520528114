import React from 'react'

import { A } from '../elements/all-elements'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

export default class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="grid-row">
            <div className="column-one-fifth">
              <h2>Nalu sailing</h2>
              <p>
                2021 Nalu sailing d.o.o.<br />
                All rights reserved.
              </p>
              <div className="button-group">
                <a href="https://www.instagram.com/sailingnalu/" target="_blank" rel="noreferrer" aria-label="instagram"><FontAwesomeIcon icon={faInstagram} /></a>
                <a href="https://www.linkedin.com/company/nalu-sailing" target="_blank" rel="noreferrer" aria-label="linkedin">
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </div>
              
            </div>
            <div className="column-one-sixth">
              <h2>Home</h2>
              <p>
                <A href="/#ourstory">
                  Our story
                </A>
              </p>
              <p>
                <A to="ourfleet" href="/#ourfleet">
                  Our fleet
                </A>
              </p>
              <p>
                <A to="ourfleet" href="/#book">
                  Request an offer
                </A>
              </p>
              {/* <p>
                <A to="testimonials" href="/#testimonials">
                  Testimonials
                </A>
              </p> */}
            </div>
            <div className="column-one-sixth">
              <h2>Nalu</h2>
              <p>
                <A href="/nalu#exterior">
                  Exterior
                </A>
              </p>
              <p>
                <A href="/nalu#interior">
                  Interior
                </A>
              </p>
              <p>
                <A href="/nalu#characteristics">
                  Characteristics
                </A>
              </p>
            </div>
            <div className="column-one-sixth">
              <h2>Keiki</h2>
              <p>
                <A href="/keiki#exterior">
                  Exterior
                </A>
              </p>
              <p>
                <A href="/keiki#interior">
                  Interior
                </A>
              </p>
              <p>
                <A href="/keiki#characteristics">
                  Characteristics
                </A>
              </p>
            </div>
            <div className="column-one-fifth">
              <h2>Contact us</h2>
              <p><a href="mailto:hello@nalusailing.com">hello@nalusailing.com</a></p>
              <p>Nalu sailing, d.o.o., Varaždin</p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}